// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-challenge-js": () => import("./../../../src/pages/challenge.js" /* webpackChunkName: "component---src-pages-challenge-js" */),
  "component---src-pages-codeofconduct-js": () => import("./../../../src/pages/codeofconduct.js" /* webpackChunkName: "component---src-pages-codeofconduct-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-donate-success-js": () => import("./../../../src/pages/donate-success.js" /* webpackChunkName: "component---src-pages-donate-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journal-js": () => import("./../../../src/pages/journal.js" /* webpackChunkName: "component---src-pages-journal-js" */),
  "component---src-pages-malawi-js": () => import("./../../../src/pages/malawi.js" /* webpackChunkName: "component---src-pages-malawi-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-participate-js": () => import("./../../../src/pages/participate.js" /* webpackChunkName: "component---src-pages-participate-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-solution-js": () => import("./../../../src/pages/solution.js" /* webpackChunkName: "component---src-pages-solution-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */),
  "component---src-pages-tanzania-js": () => import("./../../../src/pages/tanzania.js" /* webpackChunkName: "component---src-pages-tanzania-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-team-malawi-js": () => import("./../../../src/pages/team-malawi.js" /* webpackChunkName: "component---src-pages-team-malawi-js" */),
  "component---src-pages-team-tanzania-js": () => import("./../../../src/pages/team-tanzania.js" /* webpackChunkName: "component---src-pages-team-tanzania-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

